import React, { useEffect, useRef, useState } from 'react';

import logo from 'images/common/ktp_logo_blue.png';
import useLogin from 'hooks/useLogin';
import LoadingView from 'components/common/LoadingView';
import NumberKeypad from 'components/common/NumberKeypad';

function Login() {
  const [controlCode, setControlCode] = useState('');
  const { mutate, isLoading } = useLogin();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (controlCode.length !== 10) return;
    mutate({ controlCode });
  }, [controlCode]);

  const onKeyPress = (key: any) => {
    if (key === 'Back') {
      setControlCode((prevInput) => prevInput.slice(0, -1));
      return;
    }
    if (key === 'Clear') {
      setControlCode('');
      return;
    }
    setControlCode((prevInput) => {
      return prevInput.length === 10 ? prevInput : prevInput + key;
    });
  };

  const handleContextMenu: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
  };

  return (
    <main
      onContextMenu={handleContextMenu}
      className='w-full min-h-[1920px] bg-mono-10 p-14 pt-80 overflow-hidden'
    >
      {isLoading && <LoadingView />}
      <img
        src={logo}
        height={78}
        width={316}
        alt='ktp_logo'
        className='mx-auto'
      />
      <h3 className='text-head-01 font-bold text-center mt-48 mb-20'>
        키오스크 사용을 위해
        <br />
        사업자 번호 10자리를 입력해주세요.
      </h3>
      <input
        ref={inputRef}
        className='py-8 px-12 rounded-20 placeholder:text-[42px] text-[42px] w-full mt-16  outline outline-4 outline-blue-60'
        placeholder=''
        value={controlCode}
      />
      <NumberKeypad onKeyPress={onKeyPress} containerStyle='mt-20' />
    </main>
  );
}

export default Login;
