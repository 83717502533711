import { getAxios, postAxios, putAxios } from 'api/config';
import { AdminLoginInfo, KioskStatus } from 'store/globalStateAtom';

/**로그인 */
export type LoginReqParams = {
  controlCode: string;
};
export type UserInfo = {
  kioskId: number;
  controlCode: string;
  promotionVideo: string | null;
  name: string;
};
export const login = async (payload: LoginReqParams) => {
  const res = await getAxios<UserInfo, LoginReqParams>(
    '/kiosk/sign-in',
    payload
  );
  return res.data;
};

/**환급 내역 조회 */
export type GetRefundInfoReqBody = {
  passportNumber: string;
  kioskId: number;
};
export type RefundInfo = {
  totalPrice: string;
  totalRefund: string;
  totalCount: string;
  refundInfoList: Array<{
    name: string;
    refundIndex: number;
    createdDate: string;
    price: string;
    refund: string;
    serviceType: string;
  }>;
};
export const getRefundInfo = async (body: GetRefundInfoReqBody) => {
  const res = await postAxios<RefundInfo, GetRefundInfoReqBody, null>(
    '/kiosk/refunds',
    body
  );
  return res.data;
};

/**환급 완료(송금 상태 변경) */
export type CompleteRefundsReqBody = {
  conrolCode: string;
  refundInfoList: Number[];
  bd1Count: number;
  bd2Count: number;
  hp1Count: number;
};
export const completeRefunds = async (body: CompleteRefundsReqBody) => {
  const res = await postAxios<number, CompleteRefundsReqBody, null>(
    '/kiosk/withdrawal',
    body
  );
  return res.status;
};

/** 키오스크 상태 업데이트*/
export type UpdateKioskStatusReqParams = {
  controlCode: string;
};
export type KiostStatusUpdateInfo = {
  type: 'WITHDRAWAL' | 'DEPOSIT';
  controlCode?: string;
};
export type UpdateKioskStatusReqPayload = UpdateKioskStatusReqParams &
  KioskStatus &
  KiostStatusUpdateInfo;
export const updateKioskStatus = async ({
  controlCode,
  ...body
}: UpdateKioskStatusReqPayload) => {
  const res = await putAxios<number, KioskStatus, null>(
    `/kiosk/status/${controlCode}`,
    body
  );
  return res.status;
};

/** 키오스크 상태 조회*/
export const fetchKioskStatus = async (controlCode: string) => {
  const res = await getAxios<KioskStatus, string>(
    `/kiosk/status/${controlCode}`
  );
  return res.data;
};

export type CheckControlCodeReqBody = {
  controlCode: string;
};

/** 설정 페이지 로그인 */
export const checkControlCode = async (body: CheckControlCodeReqBody) => {
  const res = await postAxios<AdminLoginInfo, CheckControlCodeReqBody, null>(
    '/kiosk/setting',
    body
  );
  return res.data;
};

export type LoggingReqBody = {
  controlCode?: number;
  data: string;
};

/** 키오스크 통신 패킷 로깅 */
export const logging = async (body: LoggingReqBody) => {
  console.log(body.data); //디버깅용
  const res = await postAxios<number, LoggingReqBody, null>('/kiosk/log', body);
  return res.status;
};

export type BillType = {
  bd1: number | null;
  bd2: number | null;
  bd3: number | null;
  hp1: number | null;
};

export type FetchBillTypePayload = {
  controlCode: number;
};
/** 권종 타입 조회 */
export const fetchBillType = async ({
  controlCode,
}: FetchBillTypePayload) => {
  const res = await getAxios<BillType, null>(
    `/kiosk/billType/${controlCode}`
  );
  return res.data;
};
